import React from 'react';
import Card from '../../components/Card';
import { Text, TitleService } from '../../styles/typography';
import { Container, Row } from '../styles';
import { useTranslation } from 'react-i18next';

const Login: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text style={{ marginTop: 0, marginBottom: 5 }}>2/6</Text>

      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('login-inicial')}
      </Text>

      <TitleService style={{ marginBottom: 0 }}>
        {t('login-title')} <br />
        {t('login-title2')} <span>{t('login-title3')}</span>
      </TitleService>
      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('Clique para avançar de etapa')}
      </Text>

      <Row>
        <Card
          title={t('login-email')}
          name="login"
          value="Somente com e-mail"
          imgName={'email.png'}
          path={'integracao'}
          description={t('login-descript-email')}
        />
        <Card
          title={t('login-social')}
          name="login"
          value="Login com Redes Sociais"
          imgName={'redes.png'}
          path={'integracao'}
          description={t('login-descript-social')}
        />
        <Card
          title={t('login-nenhum')}
          name="login"
          value="Sem login"
          imgName={'nao.png'}
          path={'integracao'}
          description={t('login-descript-nenhum')}
        />
        <Card
          title={t('login-naosei')}
          name="login"
          value="Não sei"
          imgName={'naosei.png'}
          path={'integracao'}
          description={t('login-descript-naosei')}
        />
      </Row>
    </Container>
  );
};

export default Login;
