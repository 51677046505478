import React from 'react';
import Card from '../../components/Card';

import { Text, TitleService } from '../../styles/typography';
import { Container, Row } from '../styles';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text style={{ marginTop: 0, marginBottom: 5 }}>2/6</Text>

      <Text style={{ marginTop: 6 }}>{t('app-inicial')}</Text>
      <TitleService>
        {t('app-what')} <span>{t('app-you')}</span> {t('app-need')}
      </TitleService>
      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('Clique para avançar de etapa')}
      </Text>

      <Row>
        <Card
          title={'Android'}
          name="app"
          imgName={'android.png'}
          value="Android"
          path={'integracao'}
          description={t('app-android')}
        />
        <Card
          title={'iOS'}
          name="app"
          value="iOS"
          imgName={'brand.png'}
          path={'integracao'}
          description={t('app-ios')}
        />
        <Card
          title={t('app-multiplataforma')}
          name="app"
          value="Multiplataforma"
          imgName={'ambos.png'}
          path={'integracao'}
          description={t('app-descript-multiplataforma')}
        />
      </Row>
    </Container>
  );
};

export default App;
