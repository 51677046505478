import React from 'react';
import Card from '../../components/Card';
import { Text, TitleService } from '../../styles/typography';
import { Container, Row } from '../styles';
import { useTranslation } from 'react-i18next';

const Gps: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text style={{ marginTop: 0, marginBottom: 5 }}>9/10</Text>

      <Text style={{ marginBottom: 10, marginTop: 0 }}>{t('gps-inicial')}</Text>

      <TitleService style={{ marginBottom: 0 }}>
        {t('gps-title')} <span>{t('gps-title2')} </span>
        {t('gps-title3')}
      </TitleService>
      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('Clique para avançar de etapa')}
      </Text>

      <Row>
        <Card
          title={t('gps-sim')}
          name="gps"
          value="Sim"
          imgName={'gps.png'}
          path={'mediasSociais'}
          description={t('gps-descript-sim')}
        />
        <Card
          title={t('gps-naoacho')}
          value="Não"
          name="gps"
          imgName={'nao.png'}
          path={'mediasSociais'}
          description={t('gps-descript-naoacho')}
        />
        <Card
          title={t('gps-naosei')}
          name="gps"
          value="Não sei"
          imgName={'naosei.png'}
          path={'mediasSociais'}
          description={t('gps-descript-naosei')}
        />
      </Row>
    </Container>
  );
};

export default Gps;
