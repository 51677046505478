import styled from 'styled-components';

export const ToggleButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  width: auto;
`;

export const ToggleButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  font-family: var(--lato);
  border-radius: 2rem;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 16px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 6px 12px;
  }

  &.selected {
    background-color: #dc1e45;
    color: #fff;
  }
`;
