import Global from './styles/global';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes';
import { Provider } from 'react-redux';
import store from './redux/store';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

ReactGA.initialize('AW-1006408985', {
  debug: true,
});
const App = () => {
  return (
    <>
      <Provider store={store}>
        <Global />
        <Routes />
        <ToastContainer />
      </Provider>
    </>
  );
};

export { App };
