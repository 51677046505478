import styled from 'styled-components';

export const Button = styled.button`
  background: black;
  border: none;
`;

export const Container = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  @media screen and (max-width: 500px) {
    padding: 0px 85px;
  }
`;
