import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { SubtitleNewCard, TextCircle } from '../../styles/typographyNewCard';

import { Container, CardInfo } from './styles';

interface ICard {
  description: string;
  path?: string;
  title: string;
  value?: string;
  name?: string;
}

const NewCard = ({ description, path, value, title, name }: ICard) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const width = window.innerWidth;

  const handleNextStep = () => {
    value &&
      name &&
      dispatch({ type: 'ADD_DEV_BUDGET', payload: { [name]: value } });
    path && history.push(path);
  };
  console.log(path);
  return (
    <Container onClick={handleNextStep}>
      <CardInfo>
        <SubtitleNewCard onClick={handleNextStep}>{title}</SubtitleNewCard>
        <TextCircle onClick={handleNextStep}>{description}</TextCircle>
      </CardInfo>
    </Container>
  );
};

export default NewCard;
