import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../components/Button/styles';
import { Subtitle } from '../../styles/typographyHome';
import FlagsButton from '../../components/FlagsButton';

import { Anchor, Container, Logo } from './styles';

import { useTranslation } from 'react-i18next';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    console.log(window.location.pathname);
  }, []);
  return (
    <Container>
      <Anchor>
        <Logo src="images/logo.png" />
      </Anchor>
      <Subtitle style={{ marginBottom: 40, boxShadow: 'none' }}>
        {t('txt-inicial')} <br />
        <span>{t('sub-inicial')}</span>
      </Subtitle>
      <Button onClick={() => history.push(`/ti/servicos`)}>
        {t('Lets-begin')}
      </Button>
      <FlagsButton />
    </Container>
  );
};

export default Home;
