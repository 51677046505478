import React from 'react';
import CardIntegracao from '../../components/CardIntegracao';

import { Text, TitleService } from '../../styles/typography';
import { Container, Row } from '../styles';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text style={{ marginTop: 0, marginBottom: 5 }}>3/6</Text>

      <Text style={{ marginBottom: 10, marginTop: 0 }}>{t('integracao')}</Text>
      <TitleService style={{ marginBottom: 0 }}>
        {t('integracaoQuestionTitle')} <span>{t('integracaoQuestion')}</span>{' '}
        {t('integracaoQuestionTitle2')}
      </TitleService>
      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('Clique para avançar de etapa')}
      </Text>

      <Row>
        <CardIntegracao
          title={t('Sim')}
          name="integracao"
          imgName={'paineladm.png'}
          value="Sim"
          path={'NivelProjeto'}
          description={''}
        />
        <CardIntegracao
          title={t('Não')}
          name="integracao"
          imgName={'nao.png'}
          value="Não"
          path={'NivelProjeto'}
          description={''}
        />
        <CardIntegracao
          title={t('NãoSei')}
          name="integracao"
          imgName={'naosei.png'}
          value="NãoSei"
          path={'NivelProjeto'}
          description={''}
        />
      </Row>
    </Container>
  );
};

export default App;
