import styled from 'styled-components';
import { GiClick } from 'react-icons/gi';

export const Container = styled.div`
  height: 175px;
  width: 300px;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin: 16px 16px;
  border-radius: 10px;
  border: 2px solid #ccc;
  &:hover {
    transform: scale(1.1);
    @media screen and (max-width: 500px) {
      transform: scale(1);
    }
  }
  &:active {
    transition: opacity 0.35s ease-in-out;
    background-color: #ccc;
    transform: scale(0.88);
  }
  @media screen and (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 2px solid #ccc;
    margin: 8px 0;
    width: 80vw;
    row-gap: 2px;
    height: 60px;
  }
`;

export const ClickIcon = styled(GiClick)`
  width: 40px;
  height: 40px;
  fill: var(--white);
`;

export const CardInfo = styled.div`
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
