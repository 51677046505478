import React from 'react';
import { Button, Container } from './styles';
import { useTranslation } from 'react-i18next';

const FlagsButton: React.FC = () => {
  const { i18n } = useTranslation();
  return (
    <Container>
      <Button onClick={() => i18n.changeLanguage('pt')}>
        <img
          src="https://cdn-icons-png.flaticon.com/512/630/630591.png"
          width="50"
          height="50"
        ></img>
      </Button>
      <Button onClick={() => i18n.changeLanguage('en')}>
        <img
          src="https://cdn-icons-png.flaticon.com/512/330/330459.png"
          width="50"
          height="50"
        ></img>
      </Button>
    </Container>
  );
};
export default FlagsButton;
