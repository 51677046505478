import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--primary);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Anchor = styled.a`
  text-align: center;
`;

export const Logo = styled.img`
  width: min(100%, 500px);
  height: auto;
  margin-bottom: 20px;
`;
