import React from 'react';
import NewCardMedias from '../../components/NewCardMedias';

import { Text, TitleService } from '../../styles/typography';
import { Container, Row } from '../styles';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text style={{ marginTop: 0, marginBottom: 5 }}>6/6</Text>

      <Text style={{ marginBottom: 10, marginTop: 0 }}>{t('integracao')}</Text>
      <TitleService style={{ marginBottom: 0 }}>
        {t('Por onde você nos')} <span>{t('conheceu?')}</span>
      </TitleService>

      <Row>
        <NewCardMedias
          title={t('Google')}
          name="discovered"
          value={t('Google')}
          path={'investimento'}
          description={''}
        />
        <NewCardMedias
          title={t('Instagram')}
          name="discovered"
          value={t('Instagram')}
          path={'investimento'}
          description={''}
        />
        <NewCardMedias
          title={t('Facebook')}
          name="discovered"
          value={t('Facebook')}
          path={'investimento'}
          description={''}
        />
        <NewCardMedias
          title={t('LinkedIn')}
          name="discovered"
          value={t('LinkedIn')}
          path={'investimento'}
          description={''}
        />
        <NewCardMedias
          title={t('Indicação')}
          name="discovered"
          value={t('Indicação')}
          path={'investimento'}
          description={''}
        />
        <NewCardMedias
          title={t('Plataforma de contratação')}
          name="discovered"
          value={t('Plataforma de contratação')}
          path={'investimento'}
          description={''}
        />
        <NewCardMedias
          title={t('Outro')}
          name="discovered"
          value={t('Outro')}
          path={'investimento'}
          description={''}
        />
      </Row>
    </Container>
  );
};

export default App;
