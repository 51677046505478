import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
 @font-face {
    font-family: "Cera Pro";
    src: url("fonts/cera-pro-sv/Cera Pro Black Italic.otf"),
       url("fonts/cera-pro-sv/Cera Pro Black.otf"),
       url("fonts/cera-pro-sv/Cera Pro Bold.otf"), 
       url("fonts/cera-pro-sv/Cera Pro Medium.otf"), 
       url("fonts/cera-pro-sv/Cera Pro Light.otf"), 
       url("fonts/cera-pro-sv/Cera Pro Regular Italic.otf"); 
  }
     
    html{
      margin: 0;
      padding:0;
      font-family: var(--lato);
      background-color:var(--primary);
    }
    body{
        margin: 0;
        width: 100%;
        height: 100%;
        overflow-x:hidden;
        overflow-y:auto;
        
    }
   
    
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }


  :root{
        --primary: #000;
        --primary-100: #212121;
        --primary-200: #1b1a1a;
        --primary-300: #0c0a0a;
        --secondary: #dc1e45;
        --secondary-100:#E84767;
        --secondary-200: #8D001C;
        --secondary-300: #e42333;
        --white: #fff;
        --gray: #8E8E8E;
        --dark: #000;
        --pure-red:#ff0000;
        --republic: 'HWT Republic Gothic', sans-serif;
        --lato: 'Lato', sans-serif;
        --cera: 'Cera Pro', sans-serif;
    }
`;
