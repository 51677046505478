import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Subtitle, TextCircle } from '../../styles/typography';

import { Circle, Image, Container, CardInfo } from './styles';

interface ICard {
  imgName: string;
  description: string;
  path?: string;
  title: string;
  value?: string;
  name?: string;
}

const CardIntegracao = ({
  imgName,
  description,
  path,
  value,
  title,
  name,
}: ICard) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const width = window.innerWidth;

  const handleNextStep = () => {
    value &&
      name &&
      dispatch({ type: 'ADD_DEV_BUDGET', payload: { [name]: value } });
    path && history.push(path);
  };
  console.log(path);
  return (
    <Container onClick={handleNextStep}>
      <Circle onClick={handleNextStep}>
        <Image src={`/images/${imgName}`} />
        {/* <CardAnchor style={{ marginTop: 20 }}>
          {width >= 500 ? <ClickIcon /> : 'Clique Aqui!'}
        </CardAnchor> */}
      </Circle>
      <CardInfo>
        <Subtitle onClick={handleNextStep}>{title}</Subtitle>
        <TextCircle onClick={handleNextStep}>{description}</TextCircle>
      </CardInfo>
    </Container>
  );
};

export default CardIntegracao;
