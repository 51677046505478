import React from 'react';
import Card from '../../components/Card';
import { Text, TitleService } from '../../styles/typography';
import { Container, Row } from '../styles';
import { useTranslation } from 'react-i18next';

const Payment: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text style={{ marginTop: 0, marginBottom: 5 }}>8/10</Text>

      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('payment-inicial')}
      </Text>

      <TitleService style={{ marginBottom: 5 }}>
        {t('payment-title')} <span>{t('payment-title2')}</span>{' '}
        {t('payment-title3')}
      </TitleService>
      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('Clique para avançar de etapa')}
      </Text>
      <Row>
        <Card
          title={t('payment-sim')}
          name="payment"
          value="Sim"
          imgName={'pagamento.png'}
          path={'gps'}
          description={t('payment-descript-sim')}
        />
        <Card
          title={t('payment-naoacho')}
          name="payment"
          value="Não"
          imgName={'nao.png'}
          path={'gps'}
          description={t('payment-descript-naoacho')}
        />
        <Card
          title={t('payment-naosei')}
          name="payment"
          value="Não sei"
          imgName={'naosei.png'}
          path={'gps'}
          description={t('payment-descript-naosei')}
        />
      </Row>
    </Container>
  );
};

export default Payment;
