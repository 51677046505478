import { useState } from 'react';
import { ToggleButtonsContainer, ToggleButton } from './styles';

const ToggleButtons = ({ onChange }: any) => {
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = (buttonIndex: any) => {
    setSelectedButton(buttonIndex);

    if (onChange) {
      onChange(buttons[buttonIndex]);
    }
  };

  const buttons = ['1 a 10', '11 a 50', '51 a 100', 'Acima de 100'];

  return (
    <ToggleButtonsContainer>
      {buttons.map((buttonLabel, index) => (
        <ToggleButton
          key={index}
          onClick={() => handleButtonClick(index)}
          className={selectedButton === index ? 'selected' : ''}
        >
          {buttonLabel}
        </ToggleButton>
      ))}
    </ToggleButtonsContainer>
  );
};

export default ToggleButtons;
