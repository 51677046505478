// Icons
import Home from '../pages/Home';
import Service from '../pages/Service';
import App from '../pages/App';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Payment from '../pages/Payment';
import Gps from '../pages/Gps';
import Budget from '../pages/Budget';
import Finish from '../pages/Finish';
import Integracao from '../pages/Integracao';
import NivelProjeto from '../pages/NivelProjeto';
import Urgencia from '../pages/Urgencia';
import Faturamento from '../pages/Faturamento';
import OndeConheceu from '../pages/OndeConheceu';

// Pages

const routes = {
  public: [
    {
      title: 'TI | Mind Consulting',
      path: '/ti',
      hidden: true,
      component: Home,
    },
    {
      title: 'Marketing | Mind Consulting',
      path: '/marketing',
      hidden: true,
      component: Home,
    },
    {
      title: 'TI | Mind Consulting',
      path: '/',
      hidden: true,
      component: Home,
    },
    {
      title: 'Serviços | Mind Consulting',
      path: '/ti/servicos',
      hidden: true,
      component: Service,
    },
    {
      title: 'Integração | Mind Consulting',
      path: '/ti/integracao',
      hidden: true,
      component: Integracao,
    },
    {
      title: 'Nível do Projeto | Mind Consulting',
      path: '/ti/nivelProjeto',
      hidden: true,
      component: NivelProjeto,
    },
    {
      title: 'Urgência do Projeto | Mind Consulting',
      path: '/ti/urgencia',
      hidden: true,
      component: Urgencia,
    },
    {
      title: 'Faturamento do Projeto | Mind Consulting',
      path: '/ti/faturamento',
      hidden: true,
      component: Faturamento,
    },
    {
      title: 'Por onde nos conheceu | Mind Consulting',
      path: '/ti/mediasSociais',
      hidden: true,
      component: OndeConheceu,
    },
    {
      title: 'App | Mind Consulting',
      path: '/ti/app',
      hidden: true,
      component: App,
    },
    {
      title: 'Login | Mind Consulting',
      path: '/ti/login',
      hidden: true,
      component: Login,
    },
    {
      title: 'Dashboard | Mind Consulting',
      path: '/ti/dashboard',
      hidden: true,
      component: Dashboard,
    },
    {
      title: 'Pagamento | Mind Consulting',
      path: '/ti/pagamentos',
      hidden: true,
      component: Payment,
    },
    {
      title: 'GPS | Mind Consulting',
      path: '/ti/gps',
      hidden: true,
      component: Gps,
    },
    {
      title: 'Investimento | Mind Consulting',
      path: '/ti/investimento',
      hidden: true,
      component: Budget,
    },
    {
      title: 'Finalização | Mind Consulting',
      path: '/ti/nos-conheca',
      hidden: true,
      component: Finish,
    },
  ],
};
export default routes;
