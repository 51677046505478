import React from 'react';
import Card from '../../components/Card';
import { Text, TitleService } from '../../styles/typography';
import { Container, Row, Anchor } from '../styles';
import { useTranslation } from 'react-i18next';

const Finish: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text>{t('finish-text')}</Text>
      <TitleService>
        {t('finish-what')} <span>{t('finish-you')}</span> {t('finish-need')}
      </TitleService>
      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('Clique para avançar de etapa')}
      </Text>

      <Row>
        <Anchor
          href="https://mindconsulting.com.br/wp-content/uploads/2020/02/Portfolio-Mind-Consulting-2020.pdf"
          target="_blank"
        >
          <Card
            title={'We'}
            imgName={'projects.png'}
            description={t('finish-descript')}
          />
        </Anchor>
        <Anchor href="https://mindconsulting.com.br" target="_blank">
          <Card
            title={'Make IT'}
            imgName={'site.png'}
            description={t('finish-descript2')}
          />
        </Anchor>
        <Anchor
          href="https://api.whatsapp.com/send?phone=5511997953909&text=Ol%c3%a1%2c%20tudo%20bem%3f%20Estava%20no%20site%20da%20Mind%20Consulting%20e%20gostaria%20de%20desenvolver%20o%20meu%20projeto%20com%20voc%c3%aas%21&source=&data="
          target="_blank"
        >
          <Card
            title={'Happen'}
            imgName={'contact.png'}
            description={t('finish-descript3')}
          />
        </Anchor>
      </Row>
    </Container>
  );
};

export default Finish;
