import { DevBudgetType } from '../types/devBudgetTypes';
import { ADD_DEV_BUDGET } from '../actions/devBudgetActions';
const initialState = {
  service: '',
  app: '',
  login: '',
  dashboard: '',
  payment: '',
  gps: '',
  budget: '',
  name: '',
  phone: '',
  description: '',
  timming: '',
  discovered: '',
  size: '',
};

export type DevBudgetAction = {
  type: string;
  payload: DevBudgetType;
};

const devBudgetReducer = (
  state: DevBudgetType = initialState,
  action: DevBudgetAction,
) => {
  switch (action.type) {
    case ADD_DEV_BUDGET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default devBudgetReducer;
