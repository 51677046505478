import React from 'react';
import NewCard from '../../components/NewCard';

import { Text, TitleService } from '../../styles/typography';
import { Container, Row } from '../styles';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text style={{ marginTop: 0, marginBottom: 5 }}>5/6</Text>

      <Text style={{ marginBottom: 10, marginTop: 0 }}>{t('integracao')}</Text>
      <TitleService style={{ marginBottom: 0 }}>
        {t('E qual')} <span>{t('Urgência')}</span> {t('do projeto?')}
      </TitleService>
      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('Clique para avançar de etapa')}
      </Text>

      <Row>
        <NewCard
          title={t('Quero começar a desenvolver ainda essa semana')}
          name="urgencia"
          value={t('Quero começar a desenvolver ainda essa semana')}
          path={'mediasSociais'}
          description={''}
        />
        <NewCard
          title={t('Quero começar a desenvolver na próxima semana')}
          name="urgencia"
          value={t('Quero começar a desenvolver na próxima semana')}
          path={'mediasSociais'}
          description={''}
        />
        <NewCard
          title={t('Quero começar a desenvolver ainda esse mês')}
          name="urgencia"
          value={t('Quero começar a desenvolver ainda esse mês')}
          path={'mediasSociais'}
          description={''}
        />
        <NewCard
          title={t('Quero começar a desenvolver no próximo mês')}
          name="urgencia"
          value={t('Quero começar a desenvolver no próximo mês')}
          path={'mediasSociais'}
          description={''}
        />
        <NewCard
          title={t('Não tenho urgência para desenvolver o projeto')}
          name="urgencia"
          value={t('Não tenho urgência para desenvolver o projeto')}
          path={'mediasSociais'}
          description={''}
        />
      </Row>
    </Container>
  );
};

export default App;
