import React from 'react';
import NewCard from '../../components/NewCard';

import { Text, TitleService } from '../../styles/typography';
import { Container, Row } from '../styles';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text style={{ marginTop: 0, marginBottom: 5 }}>7/10</Text>

      <Text style={{ marginBottom: 10, marginTop: 0 }}>{t('integracao')}</Text>
      <TitleService style={{ marginBottom: 0 }}>
        {t('Como você pensa em')} <span>{t('faturar')}</span>{' '}
        {t('com o projeto?')}
      </TitleService>
      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('Clique para avançar de etapa')}
      </Text>

      <Row>
        <NewCard
          title={t('Irei vender um produto/serviço diretamente pelo sistema')}
          name="faturamento"
          value={t('Irei vender um produto/serviço diretamente pelo sistema')}
          path={'pagamentos'}
          description={''}
        />
        <NewCard
          title={t(
            'Irei cobrar um comissionamento ou mensalidade com o sistema',
          )}
          name="faturamento"
          value={t(
            'Irei cobrar um comissionamento ou mensalidade com o sistema',
          )}
          path={'pagamentos'}
          description={''}
        />
        <NewCard
          title={t('Não pretendo lucrar com o projeto / Outro.')}
          name="faturamento"
          value={t('Não pretendo lucrar com o projeto / Outro.')}
          path={'pagamentos'}
          description={''}
        />
      </Row>
    </Container>
  );
};

export default App;
